<template>
  <div class="referral-page-container" v-if="!isLoading">
    <header-container></header-container>
    <div class="auth-el on-mobile">
      <referral-card
          :showFooter="true"
          :showDirectRefer="true"
          :referralConfig="referralConfig"
          :showDefaultText="showDefaultText">
        <v-form
            class="form-style"
            v-model="isValid"
        >
          <div class="card-description" v-html="getReferralInfoText"></div>
          <div class="referral-subtext">
            {{ $t("referral.referrerInputTitle") }}
          </div>
          <div class="simple-row referral-row">
            <v-text-field
                v-model="referrerWallet"
                :label="$t('referral.referrerInputLabel')"
                outlined
                dense
                :rules="inputRules"
                style="width: 290px !important;"
                type="text"
                required
                class="referral-input"
                single-line
                :readonly="!!referralLink"
            ></v-text-field>
          </div>

          <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>

          <div v-if="referralLink">
            <div class="simple-row referral-row">
              <v-text-field
                  ref="textToCopy"
                  v-model="referralLink"
                  :label="$t('referral.referredInputLabel')"
                  outlined
                  dense
                  style="width: 290px !important;"
                  type="text"
                  required
                  class="referral-input"
                  single-line
                  readonly
              ></v-text-field>
            </div>
            <div
                style="text-align: center; color: #707070; margin-bottom: 10px;"
            >
              {{ $t("referral.shareLink") }}
            </div>
            <v-btn
                class="referral-submit-button"
                color="primary"
                @click="copyReferralLink"
            >
              {{ $t("referral.copyLink") }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
                class="referral-submit-button"
                style="margin-bottom: 30px;"
                color="primary"
                @click="createReferralClicked"
                :disabled="!isValid"
            >
              {{ $t("referral.referralLink") }}
            </v-btn>
            <a
                style="font-weight: bold; color: #e2136e; text-decoration: underline;"
                href="https://www.bkash.com/bn/bizdashboard_referral_2022"
            >
              {{ $t("referral.conditions") }}
            </a>
          </div>
        </v-form>
      </referral-card>
    </div>
  </div>
</template>

<script>
import ReferralCard from "./ReferralCard.vue";
import HeaderContainer from "@/components/referral/HeaderContainer";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  name: "ReferralLinkCreate",
  mixins: [recaptchaMixin],
  components: {
    ReferralCard,
    HeaderContainer,
  },

  data() {
    return {
      doesReferredHaveEmail: false,
      referrerWallet: null,
      referralLink: null,
      referralConfig: {},
      inputRules: [
        (v) => !!v || "Input is required",
        (v) => (v && v.length === 11) || "Number must have 11 digit",
      ],
      isValid: true,
      isLoading: false,
      showDefaultText: true,
      defaultInfoText: {
        en: "<p style='text-align: center;color: #707070;margin-bottom: 0 !important;'>To invite merchants to join bKash Business Dashboard, you can create a link or use their mobile number and share it with them</p>",
        bn: "<p style='text-align: center;color: #707070;margin-bottom: 0 !important;'> মার্চেন্টদেরকে বিকাশ বিজনেস ড্যাশবোর্ডে যোগদানের জন্য আপনি একটি লিঙ্ক তৈরি করতে পারেন অথবা তাদের মোবাইল নম্বর ব্যবহার করে  তাদের সাথে শেয়ার করতে পারেন</p>"
      }
    };
  },
  created() {
    this.getReferralConfig();
  },
  methods: {
    async createReferralClicked() {
      try {
        this.$feedback.showLoading();

        await this.loadAndPrepareRecaptchaDetails();

        let payload = {
          referrerWallet: this.referrerWallet
        }
        payload.recaptchaDetails = this.recaptcha;

        await this.$cppClientExternalServices.post("referral/link/validate-referrer", payload);
        this.$feedback.hideLoading();
        let message = "Your Referral Link has been created";
        this.$feedback.showSuccessMessage(message);
        this.referralLink =
            process.env.VUE_APP_OWN_BASE_URL + "/sign-up/?referrerWallet=" + this.referrerWallet;

        await this.forceCloseCaptchaV2();

      } catch ({response}) {
        this.$feedback.hideLoading();
        let {data} = response;
        if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          await this.$feedback.showFailed(response);
          await this.startCaptchaV2Flow();
        } else {
          await this.$feedback.showFailed(response);
        }
      }
    },
    async getReferralConfig() {
      const reqObj = {};
      this.$feedback.showLoading();
      this.isLoading = true;
      try {
        const {data} = await this.$cppClientExternalServices.post("referral/configuration/get", reqObj);
        this.$feedback.hideLoading();
        this.isLoading = false;

        if (data.referralCampaignConfig) {
          this.showDefaultText = false
          const parsedData = JSON.parse(data.referralCampaignConfig);
          this.referralConfig = parsedData;
        }
      } catch ({response}) {
        this.showFallBackData = true;
        this.$feedback.hideLoading();
        this.isLoading = false;
      }
    },
    copyReferralLink() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.value =
          "Hello! I am inviting you to sign up for the bKash business dashboard link: " +
          textToCopy.value;
      textToCopy.select();
      console.log(textToCopy.value);
      let copied = document.execCommand("copy");
      if (copied) {
        this.$gtag.event("Referral link created", {
          event_category: "Referral Link Creation Page",
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
  },
  computed: {
    getLang() {
      return this.$i18n.locale === 'বাংলা' ? 'bn' : 'en';
    },
    getReferralInfoText() {
      if (this.showDefaultText) {
        return this.defaultInfoText[this.getLang];
      }
      return this.referralConfig.referralTextConfig.referralInfoText1[this.getLang];
    }
  },
};
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;
$mobileS: 370px;

.referral-page-container {
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $MedW) {
    width: 100%;
    background-size: 100% 100%;
  }
  @media (max-width: $mobileW) {
    background-size: 100% 100%;
  }

  .body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
}

.card-description {
  font-family: Roboto;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}

.form-style {
  width: 330px;
  display: inline-block;
}

.referral-submit-button {
  margin-top: 0;
  min-width: 100%;
  width: 100% !important;
  max-width: 100%;
  height: 75px;
  padding: 13px 69px 12px 70px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #e2136e;

  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
}

.referral-subtext {
  text-align: left;
  color: #707070;
}

.referral-link-container {
  margin-top: 20px;

  font-family: HelveticaNeue;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #635959;

  .bolder {
    font-weight: bold;
    color: #e2136e;
  }
}

.referral-checkbox {
  max-width: 400px;
  margin-top: -10px;
}

.referral-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80%;
  flex-direction: column;

  .referral-title {
    font-family: Roboto;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: white;
    margin-bottom: 40px;

    .referral-amount {
      font-size: 80px;
      color: #e2136e;
    }
  }

  .referral-row {
    max-width: 400px;
  }

  .referral-input {
    margin-top: 8px;
  }

  .referral-link-container {
    margin-top: 20px;

    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #635959;

    .bolder {
      font-weight: bold;
      color: #e2136e;
    }
  }

  .referral-submit-button {
    margin-top: 20px;
    min-width: 100%;
    width: 100% !important;
    max-width: 100%;
    height: 69px;
    padding: 13px 69px 12px 70px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #e2136e;

    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
  }

  .referral-checkbox {
    max-width: 400px;
    margin-top: -10px;
  }

  .referral-description-container {
    margin-top: 20px;

    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: white;

    .bolder {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  @media (max-width: $mobileW) {
    .referral-submit-button {
      width: 250px;
      font-size: 18px;
      height: 40px;
      margin-top: 4px;
    }

    .referral-title {
      font-size: 30px;
      margin-bottom: 2px;
      padding: 12px;

      .referral-amount {
        font-size: 36px;
      }
    }

    .referral-link-container {
      font-size: 20px;
      margin-left: 2px;
    }

    .referral-description-container {
      font-size: 20px;
      margin-left: 2px;
      color: #635959;
      margin-top: 2px;
    }

    .card-description {
      font-size: 18px;
    }

    .form-style {
      width: 312px;
    }

    .referral-row {
      max-width: none;
    }
  }
}

@media (max-width: $mobileW) {
  .on-mobile {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: $mobileS) {
  .form-style {
    width: 312px;
  }
}

.recaptcha-v2-container {
  /*width: 329px !important;*/
  /*margin: auto;*/
  margin-bottom: 12px;
}

@media screen and (max-width: 425px) {
  .recaptcha-v2-container {
    width: 250px !important;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}


</style>
